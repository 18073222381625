import React from 'react';
import CustomChatBotWidget from '../Chat/ChatWidget';
import './Chatbot.styles.css';


const Chatbot = (props) => {
  return (
    <div className="main_container">
      <div className="chat-container">
        <div className="chatbot-container">
          <CustomChatBotWidget fullHeight isIframe />
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
