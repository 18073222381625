const api = `/api`
const authApiES = `http://127.0.0.1:5000`
const pensdownApi = `https://pensdown-dev-uejwvhorma-ue.a.run.app/api/pensdown`
const AUTH = {
    LOGIN: `${api}/login`,
    SIGNUP: `${api}/signup`
}

const GET = {
    VERIFY_TOKEN: `${api}/access_token`,
    REMOVE_TOKEN: `${api}/remove_token`,
    ALL_USERS: `${api}/get-all-users`,
    ALL_NEW_USERS: `${api}/get-all-new-users`,
    TOTAL_USERS: `${api}/get-total-users`,
    GET_DOCS_BY_USERS: `${api}/get-user-documents-details`,
    GET_ALL_DOCS_COUNT: `${api}/get-all-documents-count-data`,
    GET_USER_DOCS_SIZE: `${api}/get-user-documents-size`,
    SEARCH_USER: `${api}/search-users`,
    GET_ALL_USERS_BY_ROLES: `${api}/get-all-users-by-roles`,
    GET_TOTAL_SEARCHES_BY_USER: `${api}/get-total-searches-by-user`,
    GET_TOTAL_SEARCHES: `${api}/get-total-searches`,
    GET_TOTAL_DOCUMENT: `${api}/get-total-document`,
    GET_TOTAL_SEARCHES_MONTH: `${api}/get-total-search-month`,
    GET_TOTAL_DOCS_USER: `${api}/get-total-doc-user`,
    CONFIGURATION: `${api}/get-configuration`,
}

const POST = {
    LOGIN: `${api}/login`,
    REGISTER: `${api}/register`,
    GET_FILE_TABLES: `${api}/get-file-tables`,
    OPENAI_CHAT: `${api}/openai-chat`,
    LANGCHAIN_CHAT: `${api}/chat-langchain`,
    ADD_CONFIGURATIONS: `${api}/add-configurations`,
    ADD_FEEDBACK: `${api}/add-feedback`,
    DELETE_FILES: `${api}/delete-files`,
    GET_CHEOUT_SESSION: `${api}/create-stripe-session`,
}

export {
    AUTH,
    GET,
    POST,
}