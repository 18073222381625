
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { allPaths } from '../../utils/constants';
import JK_LOGO from '../../assets/JK.png'
import Communication from '../../assets/Communicationwithairobot.png'
import Business from '../../assets/BusinessAnalysis.png'
import AIInnovation from '../../assets/AIInnovation.png'
import { Button } from 'antd';

import './UseCases.styles.css'

const UseCases = (props) => {
    const user = useSelector((state) => state?.authReducer?.user)
    const navigate = useNavigate();

    const goToModels = () => {
        navigate(allPaths?.AIMODELS)
    }

    return (
        <div className='main_container' style={{ height: "100vh", padding: "10px 0" }}>
            <div className="blue-grad-L"></div>
            <div class="usecase-radial-lines-card"></div>
            <div class="dots-card"></div>
            <div class="dots-card-2"></div>
            <div className="green-grad-R"></div>
            <div className='usecase-center_cont'>
                <img src={JK_LOGO} className='JK_logo' />
                <p className='welcome'>
                    Use Cases
                </p>
                <span style={{ marginBottom: 10 }}>Have more questions? Contact us at <a href="j@jakmet.ai" >j@jakmet.ai</a>!</span>
                <div className="usecase-row">
                    <div className="usecase-column-left">
                        <img className='card_image' src={Communication} />
                    </div>
                    <div className="usecase-column-right">
                        <p className='usecase-heading'>Training</p>
                        <h4>Corporate Training and Support</h4>
                        <p className='tag_line_card'>
                            A legal office wishes to speed up onboarding and job training of new staff. Using a Generative AI assistant named ‘Thomas’, new staff now get personalized training programs and certification tests.
                        </p>
                        <div className='usecase-btn'>
                            <Button onClick={goToModels}>Read More</Button>
                        </div>
                    </div>
                </div>
                <div className="usecase-row usecase-row-reverse">
                    <div className="usecase-column-left">
                        <img className='card_image' src={Business} />
                    </div>
                    <div className="usecase-column-right">
                        <p className='usecase-heading'>Business</p>
                        <h4>Business Administration</h4>
                        <p className='tag_line_card'>
                            An enterprising intern at a consumer electronics company wants to see if there is a way for LLM’s to improve sales. He notices that customers spend a lot of time on product description pages.
                        </p>
                        <div className='usecase-btn'>
                            <Button onClick={goToModels}>Read More</Button>
                        </div>
                    </div>
                </div>
                <div className="usecase-row">
                    <div className="usecase-column-left">
                        <img className='card_image' src={AIInnovation} />
                    </div>
                    <div className="usecase-column-right">
                        <p className='usecase-heading'>Learing</p>
                        <h4>Education</h4>
                        <p className='tag_line_card'>
                            A small private school wants to raise its students’ standardized test scores and college acceptance rates. They decide to create a Gen AI tutor named ‘Alfred’. Alfred is very carefully trained to support students across a range of subjects.
                        </p>
                        <div className='usecase-btn'>
                            <Button onClick={goToModels}>Read More</Button>
                        </div>
                    </div>
                </div>
                <div className='models-buttons'>
                    <div className='back-button'>
                        <Button onClick={() => navigate(allPaths.LOGIN)}>Back</Button>
                    </div>
                    <div className='signin'>
                        <Button onClick={() => navigate(allPaths.FAQ)}>
                            See FAQs
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UseCases