import 'antd/dist/antd.min.css'
import AOS from 'aos'
import React from 'react'
import { AllRoutes } from './Config/routes'

AOS.init()

const App = () => {
  return (
    <div>
      <AllRoutes />
    </div>
  )
}


export default App