const HOME = '/'
const LOGIN = '/login'
const SIGNUP = '/register'
const RESET = '/reset'
const USERS = '/users'
const SINGLE_USER = '/user'
const PRICING = '/pricing'
const AIMODELS = '/models'
const CHAT = '/chat'
const IFrame = '/iframe'
const CHATBOT = '/chatbot'
const USECASES = '/usecases'
const FAQ = '/faq'

export default {
    HOME,
    LOGIN,
    SIGNUP,
    RESET,
    USERS,
    SINGLE_USER,
    PRICING,
    AIMODELS,
    CHAT,
    CHATBOT,
    IFrame,
    USECASES,
    FAQ
}