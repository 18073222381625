import React, { useEffect, useState } from 'react';
import { Layout, Menu, Collapse, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { allPaths } from '../../utils/constants';
import JK_LOGO from '../../assets/JK.png';

import './Faqs.style.css';

const { Header, Content, Sider } = Layout;
const { Panel } = Collapse;
const Faqs = (props) => {
  const user = useSelector((state) => state?.authReducer?.user);
  const navigate = useNavigate();

  const goToModels = () => {
    navigate(allPaths?.AIMODELS);
  };

  const faqContent = {
    "What is JakMet?": "JakMet is the world’s first AISP - Artificial Intelligence Service Provider. Similar to how an ISP provides you with internet service, JakMet provides you with AI service.",
    "Why should I care?": "AI is the next big utility. Like electricity, telephone service, and the internet before it, it will soon become a standard tool that everyone interfaces with daily without even really thinking about it.",
    "Who is JakMet for?": `JakMet is for any person or organization seeking access to artificial intelligence to serve their goals. JakMet is for businesses that wish to set up an AI office aid to streamline administrative tasks. JakMet is for schools that wish to create an AI homework tutor for students to use at home. JakMet is for individuals doing research and in need of a virtual assistant to organize their work. JakMet is for private companies that wish to create LLM-powered apps integrated with their own proprietary technology and private data and salable to end users. JakMet is for non-technical people from all walks of life who want to start understanding and using AI today on their own terms.

    Perhaps most importantly, JakMet is for people that want to own the AI service they pay for and use it freely without submitting to some big tech conglomerate’s politicized terms of service or giving up all of their data and a cut of their earnings.`,
    "What can JakMet actually do as of today?": "JakMet will automate creation of a custom LLM AI service for you or your business. It will allow you to choose a model, design your interface, fine-tune with your own custom data, and then serve the final working system through your own website or app. Even if you have zero experience in technology JakMet lets you go from zero to functional AI tool in minutes.",
    "What are some concrete examples of how JakMet can help me?": `Imagine you are a car dealership that wants to create really nice customer-specific brochures or automate CRM data input and analysis work. Imagine you are a school that wants to provide deeper feedback than “NS” for “Not Satisfactory” on standardized tests for thousands of students, or perhaps want to offer a virtual test expert that students can ask about their scores to zero in on their needs. LLM technologies can create all sorts of rich possibilities for organizations. The limit truly is our imagination. Properly integrated into a business, LLMs can get $2 million in output from a $1 million HR spend.

    JakMet empowers all of this most critically with its extremely fast onboarding and by managing all of the cloud infrastructure on behalf of clients. Just as your ISP handles all of the infrastructure for internet access, JakMet handles your AI access without your having to think about cloud servers, API changes, data storage, etc.`,
    "How much does it cost?": "Similar to other utilities like electricity, cost varies based on usage. We offer free tiers for very light usage and open source models. We have a wide range of plans for individuals and different types of organizations based on low, moderate, and high usage allocations. Paid plans start at $10 per month.",
    "Wouldn’t it be cheaper/easier to do it myself?": `This is very unlikely. Setting up an LLM system for an organization generally costs tens if not hundreds of thousands of dollars of development effort. Even if you have in-house expertise to reduce that cost you still have to account for hosting, maintenance, scaling, security, patching, upgrading, monitoring, and feature development.

    With JakMet there is no setup fee and no cloud hosting cost. We take a small margin of fractions of a cent on API costs that you would have to pay anyway if you tried to do everything yourself. Your maaintenance and monitoring costs alone are likely to far exceed our margin. With JakMet you also get the network effect bonus of a large ecosystem keeping up to date with the latest AI services, adding new features, and ensuring strong security and uptime guarantees.
    
    Trying to be your own AI Service Provider is akin to trying to be your own ISP or provide your own electricity; in rare cases it can work but 99.99% of the time it is a bad idea.`,
    "What is the philosophy behind JakMet?": `Stated concisely, we believe in democratization of access to AI under public/private utility provider paradigm. Mass adoption of AI technology is inevitable, as was the case with electricity and broadband internet. Whether or not this is a net benefit to humanity will depend upon how we regulate the technology. By treating AI as a utility we believe it is possible to establish necessary guard rails to prevent catastrophic outcomes while also ensuring widespread access to the benefits of AI thereby reducing inequality.`,
    "What is the future of JakMet?": `Our intention is to stay ahead of the curve in the field of AI services. Right now the major breakthroughs are in the LLM Generative AI space, so that is where we have started. We will expand our offerings in this space by supporting more models, quality of life features, more integrations, self-hosting, and other improvements.

    Longer term we intend to offer other non-LLM AI services as the field grows and expands. We seek to be an open access point through which our users can benefit from all of the latest innovations in the field as it evolves, similar to the history of other utilities.`
  };

  const customExpandIcon = (props) => {
    if (props.isActive) {
      return <MinusOutlined style={{ color: '#37619B' }} />;
    }
    return <PlusOutlined style={{ color: '#37619B' }} />;
  };

  return (
    <div className="main_container">
      <div className="">
        <div className="blue-grad-L"></div>
        <div class="radial-lines-card"></div>
        <div class="dots-card"></div>
        <div class="dots-card-2"></div>
        <div className="green-grad-R"></div>
        <div className="faq-center_cont" style={{ zIndex: 99999 }}>
          <img src={JK_LOGO} className="JK_logo" />
          <p className="welcome">Questions? Look here</p>
          <span>Can’t find an answer? Contact us at <a href="j@jakmet.ai" >j@jakmet.ai</a>!</span>
          <Layout style={{ width: '90%', background: 'transparent' }}>
            <Sider width={200} style={{ background: 'transparent', marginTop: '50px', }}>
              <h4>Table of Contents </h4>
              <Menu
                mode="inline"
                defaultSelectedKeys={['general']}
                style={{
                  height: '100%',
                  borderRight: 0,
                  background: 'transparent',

                }}
              >
                <Menu.Item key="general">General</Menu.Item>
                <Menu.Item key="">Trust & Safety</Menu.Item>
                <Menu.Item key="">Billing</Menu.Item>
                <Menu.Item key="">Services</Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{ padding: '24px', background: 'transparent' }}>
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                <Collapse
                  accordion
                  expandIcon={({ isActive }) => customExpandIcon({ isActive })}
                >
                  {Object.entries(faqContent).map(([key, value], index) => (
                    <Panel header={key} key={index + 1}>
                      <p style={{ paddingLeft: "25px" }}>{value}</p>
                    </Panel>
                  ))}
                </Collapse>
              </Content>
            </Layout>
          </Layout>
          <div className="faq-buttons">
            <div className="back-button">
              <Button onClick={() => navigate(allPaths.LOGIN)}>Back</Button>
            </div>
            <div className="signin">
              <Button onClick={() => navigate(allPaths.USECASES)}>See Use Cases</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
