const bgColor = '#0adc00'
const apiUrl = `http://localhost:8080`
import allPaths from '../Config/paths'

export {
    bgColor,
    apiUrl,
    allPaths
}

