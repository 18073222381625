import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Reset = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>
      <p>
        RESET
      </p>
    </>
  )
}
export default Reset;