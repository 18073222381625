// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-header {
    position: fixed;
    z-index: 999999;
    top: 0;
    width: 100%;
    background-color: #FFF;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sticky-header h1 {
    font-family: 'Proxima Soft', sans-serif !important;
    font-weight: 1000;
    letter-spacing: 0px;
    text-align: center;
    background: linear-gradient(93.04deg, #4273B8 4.29%, #4DB748 92.42%);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    color: transparent !important;
    margin: 0;
  }
  
  .sticky-header button {
    padding: 5px 10px;
    background-color: #34A853;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .sticky-header button:hover {
    background-color: #45a049;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,MAAM;IACN,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,wCAAwC;IACxC,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,kDAAkD;IAClD,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,oEAAoE;IACpE,wCAAwC;IACxC,gCAAgC;IAChC,6BAA6B;IAC7B,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".sticky-header {\n    position: fixed;\n    z-index: 999999;\n    top: 0;\n    width: 100%;\n    background-color: #FFF;\n    padding: 10px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .sticky-header h1 {\n    font-family: 'Proxima Soft', sans-serif !important;\n    font-weight: 1000;\n    letter-spacing: 0px;\n    text-align: center;\n    background: linear-gradient(93.04deg, #4273B8 4.29%, #4DB748 92.42%);\n    -webkit-background-clip: text !important;\n    background-clip: text !important;\n    color: transparent !important;\n    margin: 0;\n  }\n  \n  .sticky-header button {\n    padding: 5px 10px;\n    background-color: #34A853;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .sticky-header button:hover {\n    background-color: #45a049;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
