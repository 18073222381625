import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './Header.css'; // Import your CSS file
import { allPaths } from '../utils/constants';
import { loginUser } from '../Redux/actions/authActions';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(loginUser(null));
    navigate(allPaths.LOGIN);
  };

  return (
    <div className="sticky-header">
      <h1>JakMet</h1>
      <button onClick={handleLogout} className="">
        Logout
      </button>
    </div>
  );
};

export default Header;
