
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { allPaths } from '../../utils/constants';
import JK_LOGO from '../../assets/JK.png'
import Copy from '../../assets/copy.png'



import './IFrame.style.css'
import { Button, Input, Select } from 'antd';
import { successMessage } from '../../utils/helpers';

const IFrame = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.authReducer?.user)
  const baseUrl = window.location.origin;
  const [height, setHeight] = useState(400)
  const [width, setWidth] = useState(400)

  const iFrameURL = `${baseUrl}/chatbot?chat_id=${user?.user_id}`

  const backCalling = () => {
    navigate(-1)
  }

  const pricingNavigate = () => {
    navigate(allPaths?.CHAT)
  }

  const handleHeightPosition = () => {

  }

  const handleHeightUnit = () => {

  }

  const handleWidthUnit = () => {

  }

  const copyToClipboard = (text) => {
    successMessage("iFrame copied to clipboard")
    navigator?.clipboard?.writeText(text)
      .then(() => {
        // console.log('Text copied to clipboard:', text);
        // Optionally, show a success message or perform other actions
      })
      .catch(err => {
        console.error('Unable to copy to clipboard:', err);
        // Handle error (e.g., show error message)
      });
  }

  return (
    <div className="main_container">
      <div className="">
        <div className="background-color-card"></div>
        <div className="radial-lines-card"></div>
        <div className="dots-card"></div>
        <div className="dots-card-2"></div>
        <div className="iframe-bg"></div>
        <div className=""></div>
        <div className="center_cont">
          <img src={JK_LOGO} className="JK_logo" />
          <p className="welcome">Your Gen AI system is ready!</p>
          <div className="model-row" style={{ paddingLeft: "65px" }}>
            <div className='iframe-header'>
              <span
                className="gradient business-text"
                style={{ marginLeft: 0 }}
              >
                BUSINESSES
              </span>
              <h4>Integrate Seamlessly Into Your Site</h4>
              <p className="tag_line_card">
                Elevate your digital presence by embedding your tailored AI system directly onto your platform.
              </p>
            </div>
            <div className="iframe-input-container">
              <span className="iframe-text">Width</span>
              <div>
                <Input
                  className="width"
                  type="number"
                  min={1}
                  value={width}
                  placeholder=""
                  onChange={(e) => setWidth(e.target.value)}
                />
                <Select
                  defaultValue='px'
                  onChange={handleWidthUnit}
                  options={[
                    {
                      value: 'px',
                      label: 'px',
                    },
                    {
                      value: '%',
                      label: '%',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="iframe-input-container">
              <span className="iframe-text">Height</span>
              <div>
                <Input
                  className="width"
                  type="number"
                  placeholder=""
                  min={1}
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
                <Select
                  defaultValue='px'
                  onChange={handleHeightUnit}
                  options={[
                    {
                      value: 'px',
                      label: 'px',
                    },
                    {
                      value: '%',
                      label: '%',
                    },
                  ]}
                />
                <Select
                  defaultValue='fixed'
                  onChange={handleHeightPosition}
                  options={[
                    {
                      value: 'fixed',
                      label: 'Fixed',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="model-row" style={{ width: "90%", padding: "30px", display: "flex", marginBottom: "10px", alignItems: "flex-start" }}>
              <span>
                {`<iframe src = ${iFrameURL}
                width = ${width} height = ${height}
                frameborder = "0" scrolling = "no" >
                </iframe > `}
              </span>
              <img src={Copy} style={{ marginLeft: "10px" }} onClick={() => copyToClipboard(`<iframe src=${iFrameURL}
              width=${width} height=${height}
              frameborder = "0" scrolling = "no" >
                </iframe > `)} />
            </div>
          </div>
          <div className="models-buttons">
            <div className="back-button">
              <Button onClick={backCalling}>Back</Button>
            </div>
            <div className="signin">
              <Button onClick={pricingNavigate}>Continue to Dashboard</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IFrame