import { Button, Result } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { Login, Reset, Signup, Home, Pricing, AIModels, Chat, IFrame,Chatbot, UseCases, Faqs } from '../Screens'
import allPaths from './paths'
import StickyHeader from '../Components/Header'

const Page404 = (props) => {
    const navigate = useNavigate()
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => navigate(allPaths?.HOME)}
            >Back Home</Button>}
        />
    )
}


const WrapComponent = ({ Component, ...props }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [toggleHeader, setToggleHeader] = useState(false)
    const user = useSelector((state) => state?.authReducer?.user)

    return (
        <div className='main_container'>
            {!props.isChat &&<StickyHeader/>}
            <Component {...props} dispatch={dispatch} />
        </div>
    )
}

const AllRoutes = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path={allPaths?.LOGIN} element={<Login />} />
                <Route path={allPaths?.SIGNUP} element={<Signup />} />
                <Route path={allPaths?.RESET} element={<Reset />} />
                <Route path={allPaths?.HOME} element={<WrapComponent Component={Home} />} />
                <Route path={allPaths?.PRICING} element={<WrapComponent Component={Pricing} />} />
                <Route path={allPaths?.AIMODELS} element={<WrapComponent Component={AIModels} />} />
                <Route path={allPaths?.IFrame} element={<WrapComponent Component={IFrame} />} />
                <Route path={allPaths?.CHAT} element={<WrapComponent Component={Chat} isChat/>} />
                <Route path={allPaths?.USECASES} element={<UseCases />} />
                <Route path={allPaths?.FAQ} element={<Faqs />} />
                {/* <Route path={allPaths?.CHATBOT} element={<Chatbot />} /> */}
                <Route path={allPaths?.CHATBOT} element={<Chatbot />} />
                <Route path='/:page404' element={<WrapComponent Component={Page404} />} />
            </Routes>
        </BrowserRouter>
    )
}

export {
    AllRoutes,
    Page404
}

