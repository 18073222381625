import jwt_decode from 'jwt-decode'
import axios from 'axios'

const tokenDecode = (token, url) => {
    try {
        token = token?.split('.')

        token[1] = token[1]?.slice(5,)

        token = token?.join('.')
        return jwt_decode(token)
    }
    catch (e) {
        console.log('e')
    }
}

const get = (url, params = {}) => {
    return axios.get(url, {
        ...params
    })
        .then((res) => {
            let token = res.data
            const tokenData = tokenDecode(token, url)

            // console.log('tokenData', tokenData)

            if (tokenData?.isSessionExpied) {
                // errorMessage(tokenData?.message)
                window.location.href = `${window.location.origin}/login`
                return
            }

            return tokenData
        })
        .catch((e) => e?.response?.data)
}

const post = (url, body = {}, params = {}) => {
    return axios.post(url, body, {
        ...params
    })
        .then((res) => {
            let token = res.data
            const tokenData = tokenDecode(token)

            if (tokenData?.isSessionExpied) {
                // errorMessage(tokenData?.message)
                window.location.href = `${window.location.origin}/login`
                return
            }

            return tokenData
        })
        .catch((e) => {
            console.log('e', e)

            return e?.response?.data
        })
}

const secureApi = {
    get,
    post
}

export {
    secureApi,
    tokenDecode
}