import Home from './Home/Home'
import Login from './Login/Login'
import Reset from './Login/Reset'
import Signup from './Login/Signup'
import Pricing from './Pricing/Pricing'
import AIModels from './AIModels/AIModels'
import Chat from './Chat/Chat'
import IFrame from './IFrame/IFrame'
import Chatbot from './Chatbot/Chatbot'
import UseCases from './UseCases/UseCases'
import Faqs from './Faqs/Faqs'

export {
    Home,
    Login,
    Reset,
    Signup,
    Pricing,
    AIModels,
    Chat,
    IFrame,
    Chatbot,
    UseCases,
    Faqs
}
