
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { allPaths } from '../../utils/constants';
import JK_LOGO from '../../assets/JK.png'
import { AiOutlineCheck } from "react-icons/ai";
import './pricing.styles.css'
import { Button } from 'antd';
import { POST } from '../../utils/apis';
import { secureApi } from '../../Config/api';
import { errorMessage } from '../../utils/helpers';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


const Pricing = (props) => {
    const user = useSelector((state) => state?.authReducer?.user)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams();

    const cancel = searchParams.get("cancel")

    useEffect(()=>{
        if(cancel) {
            errorMessage("Payment unsuccessful")
        }
    },[])
    
    useEffect(() => {
        if (!user) {
            navigate(allPaths?.LOGIN)
        }
    }, [])

const getCheckoutSession = async (product_id) => {
    const stripe = await stripePromise;
    setLoading(true)
    secureApi.post(POST.GET_CHEOUT_SESSION, {product_id})
      .then((data) => {
        console.log("SESSION DATA", data)
        if (data?.success) {
          setLoading(false)
          const { session } = data
          stripe.redirectToCheckout({
            sessionId: session.id,
          }).then(result =>{
              if (result.error) {
                errorMessage(result.error)
              }
          });
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log("Error in  generating session")
        errorMessage("Error in generating session",e)
      })
}

    return (
        <div className='main_container'>
            <div className="">
                <div class="background-color-card"></div>
                <div class="radial-lines-card"></div>
                <div class="dots-card"></div>
                <div class="dots-card-2"></div>
                <div className='pricing_center_cont'>
                    <img src={JK_LOGO} className='JK_logo' />
                    <p className='welcome'>
                        Select Your Pricing Tier
                    </p>
                    <div className='price-card-container'>
                        <div className="price-card">
                            <div className="price-top">
                                <h3>Starter</h3>
                                <p className='price_tag_line_card'>
                                    Essential Starter Pack: Everything You Need to Jumpstart Your Journey
                                </p>
                            </div>
                            <div className='price-bottom'>
                                <h2 className='gradient'>Free</h2>
                                <p className='price_tag_line_card'>
                                    Unlimited AI integration for businesses eager to embrace full-scale AI solutions.
                                </p>
                                <div className='signin signInFull'>
                                    <Button disabled={loading} style={{ width: '100%' }} onClick={()=>getCheckoutSession(0)}>
                                        Get started
                                    </Button>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span> No cost</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                            </div>
                        </div>
                        <div className="price-card">
                            <div className="price-top">
                                <h3>Unlimitted</h3>
                                <p className='price_tag_line_card'>
                                    Unlimited AI integration for businesses eager to embrace full-scale AI solutions.
                                </p>
                            </div>
                            <div className='price-bottom'>
                                <h2 className='gradient'>$10/month</h2>
                                <p className='price_tag_line_card'>
                                    Unlimited AI integration for businesses eager to embrace full-scale AI solutions.
                                </p>
                                <div className='signin signInFull'>
                                    <Button disabled={loading} style={{ width: '100%' }} onClick={()=>getCheckoutSession(1)}>
                                       Get stated
                                    </Button>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                            </div>
                        </div>
                        <div className="price-card">
                            <div className="price-top">
                                <h3>Commited Use</h3>
                                <p className='price_tag_line_card'>
                                    Advanced AI model for academic and research purposes, ideal for in-depth.
                                </p>
                                
                            </div>
                            <div className='price-bottom'>
                                <h2 className='gradient'>Custom</h2>
                                <p className='price_tag_line_card'>
                                    Unlimited AI integration for businesses eager to embrace full-scale AI solutions.
                                </p>
                                <div className='signin signInFull'>
                                    <Button htmlType='submit' style={{ width: '100%' }} >
                                       Contact us for pricing
                                    </Button>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div><div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                                <div className='price-package'>
                                    <AiOutlineCheck color='#6A6A6A'/>
                                    <span>Sed ut perspiciatis unde omnis</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing
