
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { errorMessage, successMessage } from '../../utils/helpers';
import { allPaths } from '../../utils/constants';
import JK_LOGO from '../../assets/JK.png'
import MAN from '../../assets/man.png'
import LAPTOP from '../../assets/laptop.png'
import { Button } from 'antd';
import AIInnovation from '../../assets/AIInnovation.png'

import './style.css'
const Home = (props) => {
    const user = useSelector((state) => state?.authReducer?.user)
    
    const navigate = useNavigate();
    useEffect(() => {
        if (!user) {
            navigate(allPaths?.LOGIN)
        }
    }, [])

    const goToModels = () => {
        navigate(allPaths?.AIMODELS)
    }

    return (
        <div className='main_container' style={{ height: "100vh", padding: " 60px 0 10px 0" }}>
            <div className="">
                <div className="blue-grad-L"></div>
                <div class="radial-lines-card"></div>
                <div class="dots-card"></div>
                <div class="dots-card-2"></div>
                <div className="green-grad-R"></div>
                <div className='faq-center_cont' style={{ zIndex: 99999 }}>
                    {/* <div className='center_cont'> */}
                    <img src={JK_LOGO} className='JK_logo' />
                    <p className='welcome'>
                        Let's start
                    </p>
                    <div className="row">
                        <div className="column-left">
                            <img className='card_image' src={MAN} />
                        </div>
                        <div className="column-right">
                            <p className='gradient'>BUSINESSES</p>
                            <h4>AI-Driven Growth for Innovative Enterprises</h4>
                            <p className='tag_line_card'>
                                Unlock the full potential of your business with smart, AI-powered strategies.
                            </p>
                            <div className='signin'>
                                <Button onClick={goToModels}>Get Started</Button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column-left">
                            <img className='card_image' src={LAPTOP} />
                        </div>
                        <div className="column-right">
                            <p className='gradient'>LEARNING</p>
                            <h4>Revolutionize Education with Tailored AI Tutoring</h4>
                            <p className='tag_line_card'>
                                Elevate the learning experience with AI that adapts to each student's journey.
                            </p>
                            <div className='signin'>
                                <Button onClick={goToModels}>Get Started</Button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column-left">
                            <img className='card_image' src={AIInnovation} />
                        </div>
                        <div className="column-right">
                            <p className='gradient'>INDIVIDUALS</p>
                            <h4>Supercharge Your Potential with AI</h4>
                            <p className='tag_line_card'>
                                Elevate your workflow and creativity effortlessly with our tailored AI tools, designed for every ambitious mind.
                            </p>
                            <div className='signin'>
                                <Button onClick={goToModels}>Get Started</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home