import { message, notification } from 'antd'
import { secureApi } from '../Config/api'
import {GET, POST} from '../utils/apis'
const requiredMessage = (value) => `Please input your ${value}!`

const inputPlace = (value) => `Input your ${value} Here...!`

const setActiveMenu = (path) => path === allPaths.HOME ? 0 : 1

const successMessage = (desc = 'Successfully Complete!') => {
    return message.success(desc)
}

const infoMessage = (desc = 'Successfully Complete!') => {
    return message.info(desc)
}

const errorMessage = (desc = 'Oops Something Went Wrong!') => {
    return message.error(desc)
}

const warningMessage = (desc = 'Warning!') => {
    return message.warning(desc)
}

const successNotification = (message = 'Successfully Complete!') => {
    return notification.success({ message })
}

const errorNotification = (message = 'Oops Something Went Wrong!') => {
    return notification.error({ message })
}

const convertTitle = (val = '') => val?.charAt(0)?.toUpperCase() + val?.slice(1)

const stringLimiter = (val, limit = 50) => val?.length > limit ? `${val.slice(0, limit)}...` : val


const userObject = (result) => {
    const { profileObj } = result
    return {
        email: profileObj.email,
        social_id: profileObj.googleId,
        name: profileObj.givenName
    }
}

const googleLogin = async (result, history, loginUser, dispatch) => {
    const obj = userObject(result)
    axios.post(POST?.ACCOUNT_CHECK_SOCIAL_PARAMS, obj)
        .then((res) => {
            const { data } = res
            if (data?.success) {
                successMessage('Login Success!')
                dispatch(loginUser(data?.user))
                setTimeout(() => {
                    if (data?.redirectUrl) {
                        window.location.href = data?.redirectUrl
                    }
                    else {
                        history.push(allPaths?.HOME)
                    }
                }, 300)
            }
            else {
                errorMessage()
            }
        })
        .catch((err) => {
            errorMessage(err?.response?.data?.message)
        })
}

const capitalizeName = (name = '') => {
    const names = name.split(' ')
    for (let i = 0; i < names.length; i++) {
        names[i] = names[i].charAt(0).toUpperCase() + names[i].slice(1).toLowerCase()
    }
    return names.join(' ')
}

const verifyToken = (navigate, dispatch) => {
    secureApi.get(GET.VERIFY_TOKEN)
        .then((data) => {
            if (!data?.success) {
                navigate('/login')
            }
            else {
                dispatch(updateAccessToken(data?.accessToken))
                dispatch(loginUser(data?.user))
            }
        })
        .catch(() => {
            navigate('/login')
        })
}

const removeToken = (navigate) => {
    secureApi.get(GET.REMOVE_TOKEN)
        .then((data) => {
            if (data?.success) {
                navigate('/login')
            }
        })
        .catch(() => {
            navigate('/login')
        })
}

const containsUppercase = (password) => {
    return /[A-Z]/.test(password)
}

const containsSpecialCharacter = (password) => {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
}

const checkPassword = (_, value) => {
    if (!value || /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+).{12,}$/.test(value)) {
        return Promise.resolve()
    }
    else if (!containsUppercase(value)) {
        return Promise.reject(new Error('Include at least one uppercase letter.'))
    }
    else if (!containsSpecialCharacter(value)) {
        return Promise.reject(new Error('Include at least one special character.'))
    }
    else if (value?.length < 12) {
        return Promise.reject(new Error('Must have at least 12 characters.'))
    }
    else {
        return Promise.resolve()
    }
}


// Encryption function
const encryptId = (id) => {
    return btoa(id); // Using base64 encoding for simplicity
};

// Decryption function
const decryptId = (encryptedId) => {
    return atob(encryptedId); // Decoding from base64
};
export {
    capitalizeName,
    checkPassword,
    convertTitle,
    errorMessage,
    errorNotification,
    googleLogin,
    infoMessage,
    inputPlace,
    removeToken,
    requiredMessage,
    setActiveMenu,
    stringLimiter,
    successMessage,
    successNotification,
    verifyToken,
    warningMessage,
    encryptId,
    decryptId
}
