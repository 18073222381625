const reducer = (state = {}, action) => {
    switch (action.type) {
        case "LOGIN_USER": {
            return { ...state, user: action.user }
        }
        case "REMOVE_USER": {
            return { ...state, user: null }
        }
        case "GET_SEARCH_INDEX": {
            return { ...state, item: action.item }
        }
        default: {
            return state
        }
    }
}
export default reducer